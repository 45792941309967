<template>
  <div class="courseList">
    <div class="box">
      <div class="content">
        <div class="classBox">
          <p class="label">课程分类：</p>
          <div class="classContent">
            <div class="class1 class">
              <p class="classItem" :class="{ classAcitve: calssIndex1 === index }" @click="handleClass1(item, index)"
                v-for="(item, index) in class1" :key="index + 'class1'">
                {{ item.name }}
              </p>
            </div>
            <div class="class2 class" v-if="class2.length">
              <p class="classItem" :class="{ classAcitve: calssIndex2 === index }" @click="handleClass2(item, index)"
                v-for="(item, index) in class2Arr" :key="index + 'class2'">
                {{ item.name }}
              </p>
            </div>
            <div class="class3 class" v-if="class3.length">
              <p class="classItem" :class="{ classAcitve: calssIndex3 === index }" @click="handleClass3(item, index)"
                v-for="(item, index) in class3Arr" :key="index + 'class2'">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="listContainer">
          <div class="left-list">
            <div class="titleBox">
              <div class="tabs">
                <p class="tabTitle">综合排序：</p>
                <p class="tabItem" :class="{ active: allCourseTabIndex === index }" @click="handleAllCourseTab(index)"
                  v-for="(item, index) in allCourseTab" :key="item">
                  {{ item }}
                </p>
              </div>
            </div>
            <div class="listBox">
              <div class="listItem" v-for="item in AllCourseList" :key="item.ucode" @click="handleDetail(item)">
                <!-- <p class="label" v-if="item.courseType">{{ ConstData.courseLabelType[item.courseType].text  }}</p> -->
                <img :src="ConstData.courseSource[item.type].picUrl" class="project-icon" alt="" />
                <img :src="item.coverImage" class="cover" alt="" />
                <div class="info">
                  <p class="name" :title="item.name">{{ item.name }}</p>
                  <div class="bottom">
                    <p class="num">{{ item.applyNo }}人报名</p>
                    <p class="price" v-if="item.sell">
                      <!--0 免费 1收费 2咨询-->
                      <template v-if="item.goodsDto && item.goodsDto.isFree == 0">
                        <span class="salePrice">免费 </span>
                      </template>
                      <template v-if="item.goodsDto &&(item.goodsDto.isFree == 1)">
                        <span class="salePrice">¥{{ item.goodsDto?.salePrice || 0 }}</span>
                        <span class="basePrice">¥{{ item.goodsDto?.basePrice || 0 }}</span>
                      </template>
                      <template v-if="item.goodsDto &&(item.goodsDto.isFree == 2)">
                        <span class="salePrice" v-if="item.goodsDto?.salePrice>0">¥{{item.goodsDto?.salePrice}}</span>
                        <span class="basePrice" v-if="item.goodsDto?.salePrice>0 && item.goodsDto?.basePrice>0">¥{{ item.goodsDto?.basePrice}}</span>
                      </template>
                      <!-- <template v-if="item.goodsDto && !item.goodsDto.isFree">
                          <span class="salePrice">免费 </span>
                      </template>
                      <template v-else>
                          <span class="salePrice">¥{{ item.goodsDto?.salePrice || 0 }}</span>
                          <span class="basePrice">¥{{ item.goodsDto?.basePrice || 0 }}</span>
                      </template> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <article v-if="noData" class="ListEmptyData">
              <img src="../../../assets/images/course-icon.png" alt />
              <p>没有找到任何课程</p>
            </article>
          </div>
        </div>
        <div class="pagination">
          <p class="total">共{{ total }}个课程</p>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="pageNum" :page-size="pageSize" layout="prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="right-ranking">
        <div class="titleBox">
          <img src="../../../assets/images/course/courseRanking.png" class="titleImg" alt="" />
          <div class="tabs">
            <p class="tabItem" :class="{ active: courseRankingTabIndex === index }"
              @click="handleCourseRankingTab(index)" v-for="(item, index) in courseRankingTab" :key="item">
              {{ item }}
            </p>
          </div>
        </div>
        <div class="rankingList">
          <div class="item" :class="{ item1: index + 1 > 3 }" :style="index + 1 === 3 && 'marginBottom: 8px;'"
            v-for="(item, index) in rankingList" @click="handleDetail(item)" :key="item.id + 'rank'">
            <img src="../../../assets/images/course/1.png" v-if="index + 1 === 1" alt="" />
            <img src="../../../assets/images/course/2.png" v-else-if="index + 1 === 2" alt="" />
            <img src="../../../assets/images/course/3.png" v-else-if="index + 1 === 3" alt="" />
            <!-- eslint-disable -->
            <span style="margin-right: 5px" v-else>{{ index + 1 < 10 ? "0" : "" }}{{ index + 1 }}</span>
                <p class="name" :title="item.name">{{ item.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  data() {
    return {
      noData: null,
      total: 0,
      pageNum: 1,
      pageSize: 12,
      courseRankingTabIndex: 0,
      allCourseTabIndex: 0,
      allCourseTab: ["最新排序", "人气排序"],
      courseRankingTab: ["按日", "按周"],
      calssIndex1: 0,
      calssIndex2: 0,
      calssIndex3: 0,
      class1: [{ name: "全部", code: "" }],
      class2: [],
      class3: [],
      rankingList: [],
      AllCourseList: [],
      currentClassCode: "",
    };
  },
  created() {
    this.getClassifyFindAll();
    this.getCourseList();
    this.getHotList(0);
  },
  computed: {
    class2Arr() {
      return this.class2.filter((c) => c.showTag == true);
    },
    class3Arr() {
      return this.class3.filter((c) => c.showTag == true);
    },
  },
  watch: {
    "$store.state.courseCenterSearchName": {
      deep: true, //深度监听设置为 true
      handler: function (newVal) {
        console.log("数据发生变化啦"); //修改数据时，能看到输出结果
        console.log(newVal);
        this.pageNum = 1;
        this.getCourseList();
      },
    },
  },
  methods: {
    filterTreeData(data) {
      let newArr = _.filter(data, (item) => {
        return item.showTag;
      });
      return newArr.map((item) => {
        if (item.children) {
          item.children = this.filterTreeData(item.children);
        }
        return item;
      });
    },
    getHotList(type) {
      const params = {
        pageNum: 1,
        pageSize: 10,
      };
      const data = {
        scope: type, // 1 按日 2 按周
      };
      this.$api.courseCenter.courseTopCount({ params, data }).then((res) => {
        if (res.data) {
          this.rankingList = res.data || [];
        }
      });
    },
    handleClass1(item, index) {
      this.calssIndex1 = index;
      this.calssIndex2 = 0;
      this.calssIndex3 = 0;
      this.class2 = [];
      this.class3 = [];
      if (item.children && item.children.length) {
        this.class2.push(
          {
            name: "全部",
            showTag: true,
            code: item.code,
          },
          ...item.children
        );
      }
      this.currentClassCode = item.code;
      this.pageNum = 1;
      this.getCourseList();
    },
    handleClass2(item, index) {
      this.calssIndex2 = index;
      this.calssIndex3 = 0;
      this.class3 = [];
      if (item.children && item.children.length) {
        this.class3.push(
          {
            name: "全部",
            showTag: true,
            code: item.code,
          },
          ...item.children
        );
      }
      this.currentClassCode = item.code;
      this.pageNum = 1;
      this.getCourseList();
    },
    handleClass3(item, index) {
      console.log(item);
      this.calssIndex3 = index;
      this.currentClassCode = item.code;
      this.pageNum = 1;
      this.getCourseList();
    },
    // 获取所有分类
    getClassifyFindAll() {
      this.$api.courseCenter.findAll({}).then((res) => {
        if (res.data && res.data.length) {
          let arr1 = [];
          arr1 = this.filterTreeData(res.data);
          console.log(this.class1, "class1===");
          this.class1.push(...arr1);
          // _.remove(this.class1, (n) => {
          //   return n.showTag == false
          // })
        }
      });
    },
    getCourseList() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      const data = {
        name: this.$store.state.courseCenterSearchName,
        orderBy: this.allCourseTabIndex,
        categoryCode: this.currentClassCode || "",
      };
      this.$api.courseCenter.getCourseList({ params, data }).then((res) => {
        if (res.data) {
          if (res.data.list && res.data.list.length) {
            this.noData = false;
          } else {
            this.noData = true;
          }
          this.AllCourseList = res.data.list || [];
          this.total = res.data.total;
        }
      });
    },
    handleDetail(item) {
      this.$router.push({
        path: "/course/detail",
        query: {
          ucode: item.ucode,
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getCourseList();
    },
    handleAllCourseTab(index) {
      this.allCourseTabIndex = index;
      this.pageNum = 1;
      this.getCourseList();
    },
    handleCourseRankingTab(index) {
      this.courseRankingTabIndex = index;
      this.getHotList(index);
    },
  },
};
</script>

<style lang="scss" src="../assets/css/list.scss" scoped></style>
