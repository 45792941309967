<template>
  <div class="courseDetail">
    <div class="top-info">
      <div class="top-content">
        <div class="title-box" @click="$router.go(-1)">
          <svg-icon icon-class="backIcon" class-name="backIcon"></svg-icon>
          <img src="../../../assets/images/learn/course-detail-title.png" alt="" />
        </div>
        <div class="course-info" v-if="goodsInfo">
          <img :src="ConstData.courseSource[goodsInfo.type]?.picUrl" class="project-icon" alt="" />
          <img :src="goodsInfo.coverImage" class="cover" alt="" />
          <div class="info-right">
            <p class="label" v-if="goodsInfo.courseType">
              {{ ConstData.courseLabelType[goodsInfo.courseType].text }}
            </p>
            <p class="title">{{ goodsInfo.name }}</p>
            <p class="time" v-if="goodsInfo.studyType">
              有效期：{{
          goodsInfo.studyType === 1
            ? `${goodsInfo.startTime} - ${goodsInfo.endTime}`
            : goodsInfo.studyType === 2
              ? `已报名后${goodsInfo.days}天内有效`
              : "长期有效"
        }}
            </p>
            <div class="bottom">
              <p class="num">
                <svg-icon icon-class="courseIcon"></svg-icon>
                <span>{{ goodsInfo.applyNo || 0 }}人已报名</span>
              </p>
              <div class="btnBox" @click="apply">
                <el-button type="primary" v-if="goodsInfo.status === 1" :loading="Btnloading">{{ goodsInfo.isCanStudy ?
          "立即学习" : (goodsInfo.goodsDto&&goodsInfo.goodsDto.isFree==2)?"立即咨询":"立即报名" }}</el-button>
                <!--  0下架 1 上架 2 过期 3 草稿 -->
                <el-button type="primary" v-else>{{
          goodsInfo.status === 2
            ? "已过期"
            : goodsInfo.status === 3
              ? "未上架"
              : "已下架"
        }}</el-button>
              </div>
            </div>
            <p class="price" v-if="goodsInfo.sell">
              <span v-if="goodsInfo.goodsDto.isFree == 1 || goodsInfo.goodsDto.isFree == 0  || (goodsInfo.goodsDto.isFree == 2 && (goodsInfo.goodsDto?.salePrice>0))">价格：</span>
              <!--0 免费 1收费 2咨询-->
              <template v-if="goodsInfo.goodsDto && goodsInfo.goodsDto.isFree == 0">
                <span class="salePrice">免费 </span>
              </template>
              <template v-if="goodsInfo.goodsDto && (goodsInfo.goodsDto?.isFree == 1)">
                <span class="salePrice">¥{{ goodsInfo.goodsDto?.salePrice || 0 }}</span>
                <span class="basePrice">¥{{ goodsInfo.goodsDto?.basePrice || 0 }}</span>
              </template>
              <template v-if="goodsInfo.goodsDto &&(goodsInfo.goodsDto.isFree == 2)">
                <span class="salePrice" v-if="goodsInfo.goodsDto?.salePrice>0">¥{{goodsInfo.goodsDto?.salePrice}}</span>
                <span class="basePrice" v-if="goodsInfo.goodsDto?.salePrice>0 && goodsInfo.goodsDto?.basePrice>0">¥{{goodsInfo.goodsDto?.basePrice}}</span>
              </template>
              <!-- <template v-if="goodsInfo.goodsDto && !goodsInfo.goodsDto.isFree">
                <span class="salePrice">免费 </span>
              </template>
              <template v-else>
                <span class="salePrice">¥{{ goodsInfo.goodsDto?.salePrice || 0 }}</span>
                <span class="basePrice">¥{{ goodsInfo.goodsDto?.basePrice || 0 }}</span>
              </template> -->
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-tree">
      <div class="tab">
        <p class="item" :class="{ active: tabIndex === item.id }" @click="jump(item.id)" v-for="item in tabList"
          :key="item.id">
          {{ item.name }}
        </p>
      </div>
      <div class="hid-scroll-bar">
        <div class="scroll-box" id="scroll-box" @scroll="scrollFn">
          <div class="introduction" id="anchor1">
            <div class="content" v-html="goodsInfo?.description"></div>
          </div>
          <div class="catalogue" id="anchor2">
            <p class="title">课程目录</p>
            <tree :data="treeData" :taskId="taskId" :goodsInfo="goodsInfo" @clickNode="clickNode" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" custom-class="dialogW" fullscreen
      width="1040px" top="6vh" :before-close="handleClose2">
      <el-dialog :visible.sync="innerVisible" width="400px" top="25vh" :close-on-click-modal="false"
        custom-class="tipOver" append-to-body center>
        <div class="overVideo">
          <svg-icon icon-class="tips"></svg-icon>
          <div class="tryOver">试看结束</div>
          <div class="tryBottom">购买后可查看完整内容</div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false" round>取消</el-button>
          <el-button type="warning" @click="apply()" round>去报名</el-button>
        </div>
      </el-dialog>
      <tcvideoSaas v-if="videoSource == '555'" ref="tcvideoSaas2" @openIt="closeTryWatch"
        :idName="currentWatchObj ? currentWatchObj.id : ''" :isOrder="taskInfo.isOrder" />
    </el-dialog>

     <el-dialog :visible.sync="consultShow" width="400px" top="25vh" :close-on-click-modal="false"
        custom-class="tipOver" append-to-body center>
        <div class="overVideo">
          <svg-icon icon-class="tips"></svg-icon>
          <div class="tryOver">立即咨询</div>
          <div class="tryBottom">想要查看更多课程 请联系我们：4008555686</div>
        </div>
        <div slot="footer" class="dialog-footer">
          <!-- <el-button @click="innerVisible = false" round>取消</el-button> -->
          <!-- <el-button @click="innerVisible = false" round>去咨询</el-button> -->
        </div>
    </el-dialog>
 

  </div>
</template>

<script>
import _ from "lodash";
import tcvideoSaas from './../components/tcvideo'
import tree from "./../components/tree";
export default {
  components: { tree, tcvideoSaas },
  data() {
    return {
      consultShow: false,
      videoSource: '555',
      currentWatchObj: null,
      dialogVisible: false,
      innerVisible: false,
      taskInfo: {},
      tabIndex: "anchor1",
      Btnloading: false,
      offsetTop: 0,
      tabList: [
        {
          name: "简介",
          id: "anchor1",
        },
        {
          name: "目录",
          id: "anchor2",
        },
      ],
      treeData: [],
      goodsInfo: {},
      taskId: 0,
      taskRule: {}
    };
  },
  /* eslint-disable */
  computed: {
    scrollFn() {
      // 防抖
      return _.debounce(this.scroll, 100);
    },
  },
  async created() {
    await this.getCourseDetail();
    await this.taskDetailWithoutLogin();
    await this.findTaskRule()
  },
  beforeDestroy() { },
  mounted() { },
  methods: {
    // 任务规则
    async findTaskRule() {
      const res = await this.$api.coursePlay.findTaskRule(this.taskId);
      console.log('res', res);
      if (res.data) {
        this.taskRule = res.data.supervisionRule || {}
      }
    },
    toStudyDetail() {
      /**
       * displayStyle 1 列表样式，2 地图样式
      */
      if (this.taskRule.displayStyle === 1) {
        this.$router.push({
          path: "/learn/course/detail",
          query: {
            id: this.goodsInfo.id,
          },
        });
      } else {
        this.$router.push({
          path: "/learn/StageMap",
          query: {
            id: this.goodsInfo.id,
          },
        });
      }
    },
    // 报名参加
    apply() {
      // 上架的课程才能学习
      if (this.goodsInfo.status != 1) {
        return false;
      }
      if (this.Btnloading) {
        return false;
      }
      this.Btnloading = true;
      if (this.goodsInfo.isCanStudy) {  //isCanStudy  是否加入任务 
        if (
          this.$dateFormat.dateFormat() < this.taskInfo.startTime &&
          +this.taskInfo.studyType === 1
        ) {
          this.$message.warning("课程未开始");
        } else if (
          this.$dateFormat.dateFormat() > this.taskInfo.endTime &&
          (+this.taskInfo.type === 1 || +this.taskInfo.studyType === 2)
        ) {
          this.$message.warning("已到期，无法学习");
        } else {
          this.toStudyDetail();
        }
        this.Btnloading = false;
        return false;
      }else if (this.goodsInfo.goodsDto && this.goodsInfo.goodsDto.isFree == 2) {
        //咨询弹窗
        this.Btnloading = false;
        this.consultShow = true
        return false;
      }
      const params = {
        uCode: this.$route.query.ucode,
        orderSource: 1 //订单来源（1:PC,2:H5,3:安卓,4:IOS,5:小程序
      };
      this.$api.courseCenter.apply({ params }).then((res) => {
        if (res.code == 0) {
          this.Btnloading = false;
          this.goodsInfo.isCanStudy = true;
          if (res.data?.orderNo && res.data?.realPay > 0) {
            this.$router.push(`/orderPage?orderNo=${res.data.orderNo}&dymode=1`)
          } else if (res.data?.orderNo && res.data?.realPay === 0 || !res.data) { //免费的
            this.$message({
              message: '报名成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.toStudyDetail();
              },
            })
          } else {
            if (res.data.url) {
              window.open(res.data.url, '_self')
            } else {
              this.$router.go(-1)
            }
          }
        }
      }).catch((err) => {
        console.log('err', err);
        this.Btnloading = false;
      })
    },
    // 关闭试看
    closeTryWatch() {
      this.innerVisible = true;
    },
    // 点击节点
    clickNode(data) {
      if (data.type == 1) {
        return false;
      }
      if (this.goodsInfo.status != 1) {
        return false;
      }

      if (this.goodsInfo.isCanStudy) {
        this.toStudyDetail();
        return false;
      } else {
        if (data.tryWatch && data.contentType == 1) {
          //试看
          this.dialogVisible = true;
          this.currentWatchObj = data;
          this.showPreviewPlayer(data, data.watchPercent)
          return false;
        } else {
          this.$message.warning("报名后才可以查看");
        }
      }

    },
    // 获取课程大纲
    async taskDetailWithoutLogin() {
      const params = {
        id: this.goodsInfo.id,
      };
      await this.$api.courseCenter
        .taskDetailWithoutLogin({ params })
        .then((res) => {
          if (res.data) {
            this.treeData = res.data.taskItemVoList || [];
            this.taskInfo = res.data || {};
            let arr = []
            if (res.data?.courseMap) {
              arr = Object.keys(res.data?.courseMap)
              localStorage.setItem('aiCourseId', arr[0])
            }
            // 培训任务不显示阶段
            if (res.data.type == 4) {
              this.treeData = res.data.taskItemVoList[0].children || []
            }
            const obj = {};
            this.goodsInfo.tryWatchResDtoList && this.goodsInfo.tryWatchResDtoList.map((item) => {
              obj[item.resId] = item.rang;
              return item.resId;
            })
            this.addNewProp(this.treeData, obj)
          }
        });
    },
    // 获取课程信息
    async getCourseDetail() {
      const params = {
        ucode: this.$route.query.ucode,
      };
      await this.$api.courseCenter.getCourseDetail({ params }).then((res) => {
        this.goodsInfo = res.data || null;
        this.taskId = res.data.id;
      });
    },
    jump(id) {
      this.tabIndex = id;
      const box = document.getElementById("scroll-box");
      let scrollItem = document.getElementById(id);
      // 锚点对应的模块与最近的一个具有定位的祖宗元素顶部的距离
      console.log(scrollItem, "scrollItem.offsetTop");
      this.offsetTop = scrollItem?.offsetTop;
      console.log(box, "box====", this.offsetTop);
      box.scrollTo({
        top: scrollItem.offsetTop,
        behavior: "smooth",
      });
    },
    scroll() {
      const box = document.getElementById("scroll-box");
      // 若当前设置的滚动高度大于实际滚动的高度，即为锚点跳转，不再设置选中的锚点
      if (this.offsetTop > box?.scrollTop) {
        this.offsetTop = 0;
        return
      }
      let totalH = 0;
      this.tabList.some((anchor) => {
        let scrollItem = document.getElementById(anchor.id); // 锚点对应的模块
        totalH = totalH + scrollItem?.clientHeight;
        let judge = box?.scrollTop < totalH;
        if (judge) {
          this.tabIndex = anchor.id;
          return true;
        }
      });
    },
    handleClose2() {
      this.dialogVisible = false
      if (this.$refs.tcvideoSaas2) {
        this.$refs.tcvideoSaas2.dispose()
      }
    },
    // 试看
    showPreviewPlayer(row, tryWatchRang) {
      var params = ''
      this.dialogVisible = true
      params = {
        resId: row.contentId
      }
      this.$api.resource.getNoLoginPlayAuth({ params }).then(res => {
        if (res.success && res.data) {
          this.$nextTick(() => {
            if (res.data?.sourceSing == '555') {
              this.$refs.tcvideoSaas2.getTcplayer(res.data, tryWatchRang, row.courseId, row.duration)
            } else {
              const vi = Object.assign({}, res.data)
              vi.tencentPlayVideoResp = { playUrl: vi.playUrl }
              this.$refs.tcvideoSaas2.getTcplayer(vi, tryWatchRang, row.courseId, row.duration)
            }
          })
          this.dialogVisible = true;
        }
      })
    },
    // 试看属性
    addNewProp(array, obj) {
      return array.map(item => {
        if (obj[item.contentId]) {
          this.$set(item, 'watchPercent', obj[item.contentId]);
          this.$set(item, 'tryWatch', 1)
        } else {
          this.$set(item, 'tryWatch', 0)
        }
        if (item.children) {
          item.children = this.addNewProp(item.children, obj);
        }
        return item;
      });
    },
  },
};
</script>

<style lang="scss" src="../assets/css/detail.scss" scoped></style>
